import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import CollapsableListItem from "./CollapsableListItem";
import lxlogowhitegreen from "../../../../assets/lxlogowhitegreen.png";
import MuiSVGIcon from "../../../IconPicker/MuiSVGIcon";
import UserBadge from ".././../../UserBadge/UserBadge";

export default function FixedDrawer(props) {
  const { appBarHeight, navLinks, sideNavWidth, stickyNavLinks = [] } = props;
  const classes = useStyles({ sideNavWidth, appBarHeight });
  const history = useHistory();
  const isActive = (path) => {
    return history.location.pathname.includes(path);
  };

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <List>
        <ListItem className={`${classes.menuIcon} pointer`} key="logo-nav-item" onClick={()=>{
          history.push("/");
        }}>
          <Tooltip enterDelay={2000} title="Version 2.3.12" placement="right">
            <img alt="locatorx company logo" src={lxlogowhitegreen} height={"100%"} />
          </Tooltip>
        </ListItem>
        {/* <Divider /> */}

        {navLinks.map((link, idx) => (
          <ListItem
            classes={{
              root: classes.listItem,
            }}
            className={
              isActive(link.name) ? classes.listItemActive : classes.listItem
            }
            button
            key={`${link.name} - ${idx}`}
            onClick={() => {
              if (link.hyperlink) {
                window.open(link.hyperlink, "_blank", "noopener,noreferrer");
              } else {
                history.push(`${"/"}${link.name}`);
              }
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
            >
              <MuiSVGIcon
                icon={link.icon}
                key={`${link.name} - ${idx} icon`}
                sx={{ root: classes.listItemIcon }}
              />
              <ListItemText
                classes={{
                  primary: classes.listItemText,
                }}
                primary={link.label}
                key={`${link.name} - ${idx} text`}
              />
            </Box>
          </ListItem>
        ))}
        {/* For information and support menu items on the bottom of the nav */}
        <div className={classes.bottomPush}></div>
        {stickyNavLinks.map((link, idx) => {
          if (link && link.children && link.children.length) {
            return (
              <CollapsableListItem
                isActive={isActive}
                key={`${link.name} - ${idx} collapsible`}
                item={link}
                onClick={(link, isHyperLink) => {
                  if (isHyperLink) {
                    window.open(link, "_blank", "noopener,noreferrer");
                  } else {
                    history.push(`${"/"}${link}`);
                  }
                }}
              />
            );
          } else {
            return (
              <ListItem
                classes={{
                  root: classes.listItem,
                }}
                className={
                  isActive(link.name)
                    ? classes.listItemActive
                    : classes.listItem
                }
                button
                key={`${link.name} - ${idx}`}
                onClick={() => {
                  if (link.hyperlink) {
                    window.open(
                      link.hyperlink,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  } else {
                    history.push(`${"/"}${link.name}`);
                  }
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <MuiSVGIcon
                    icon={link.icon}
                    key={`${link.name} - ${idx} icon`}
                    sx={{ root: classes.listItemIcon }}
                  />
                  <ListItemText
                    classes={{
                      primary: classes.listItemText,
                    }}
                    primary={link.label}
                    key={`${link.name} - ${idx} text`}
                  />
                </Box>
              </ListItem>
            );
          }
        })}

        <ListItem
          className="pointer"
          classes={{
            root: classes.userBadgeItem,
          }}
          style={{
            marginBottom: "1rem",
          }}
        >
          <UserBadge />
        </ListItem>
      </List>
    </Drawer>
  );
}
